import React, { useContext, useEffect, useState } from "react";
import Card from "../components/clientCard";
import DownloadIcon from "../../content/imgs/icons/download.png";
import { ClientsContext } from "../../contexts/Context";
import { useNavigate } from "react-router-dom";
import { exportExcel } from "../components/export";

export default function Recolhas() {
  const navigate = useNavigate();
  const context = useContext(ClientsContext);

  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30);
  const defaultDateString = defaultDate.toISOString().split("T")[0];

  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState("");
  const [selectStats, setSelectStats] = useState("all");
  const [selectBrand, setSelectBrand] = useState("all");
  const [beginDate, setBeginDate] = useState(defaultDateString);
  const [isLoading, setIsLoading] = useState(true);
  const [showOcorrences, setShowOcorrences] = useState(false);

  const [type, setType] = useState("");

  useEffect(() => {
    if (context?.user) {
      setType(context?.user.type);
    }
  }, [context?.user]);

  useEffect(() => {
    if (
      context?.clients &&
      context?.clients.length > 0 &&
      context?.clients.some((client: any) => client?.stats === "reservado") &&
      context?.clients.some((client: any) => client?.stats === "recolhido") &&
      context?.clients.some((client: any) => client?.stats === "entregue") &&
      context?.clients.some((client: any) => client?.stats === "cancelado")
    ) {
      setIsLoading(false);
      let newCards = context.clients;
      // Search filter
      if (search.trim() !== "") {
        newCards = newCards.filter((card: any) => {
          const searchTerm = search.toLowerCase();
          return (
            card?.name.toString().toLowerCase().includes(searchTerm) ||
            card?.licensePlate.toString().toLowerCase().includes(searchTerm) ||
            (card?.alocation).toString().toLowerCase().includes(searchTerm)
          );
        });
      }
      // Filter by stats
      if (selectStats !== "all") {
        newCards = newCards.filter((card: any) => {
          return card?.stats === selectStats;
        });
      }
      // Filter by brand
      if (selectBrand !== "all") {
        newCards = newCards.filter((card: any) => {
          return card?.parkBrand.toLowerCase() === selectBrand.toLowerCase();
        });
      }

      // Filter by ocorrences
      if (showOcorrences) {
        newCards = newCards.filter((card: any) => {
          return card?.ocorrenceType;
        });
      }

      // Filter by date
      if (beginDate) {
        const [year, month, day] = beginDate
          .split("-")
          .map((num) => parseInt(num, 10));
        const beginDateObj = new Date(year, month - 1, day);
        newCards = newCards.filter((card: any) => {
          if (!card?.bookingDate) return true;
          const [datePart, timePart] = card?.bookingDate.includes(",")
            ? card.bookingDate.split(", ")
            : card.bookingDate.split(" ");
          const [day, month, year] = datePart
            .split("/")
            .map((num: any) => parseInt(num, 10));
          const [hours, minutes] = timePart
            .split(":")
            .map((num: any) => parseInt(num, 10));
          const bookingDateObj = new Date(year, month - 1, day, hours, minutes);
          return bookingDateObj >= beginDateObj;
        });
      }
      setCards(newCards.reverse());
    } else {
      context?.getClients("stats", [
        "reservado",
        "recolhido",
        "entregue",
        "cancelado",
        "em recolha",
        "em entrega",
        "em movimento",
        'pendente'
      ]);
    }
  }, [
    context?.clients,
    search,
    selectStats,
    selectBrand,
    beginDate,
    context,
    showOcorrences,
  ]);

  function handleExport() {
    const updatedCards = cards.map((card: any) => {
      const updatedExtraServices = card.extraServices
        .map((service: any) => service.pt)
        .join(", ");
      return {
        ...card,
        extraServices: updatedExtraServices,
      };
    });
    exportExcel(updatedCards, selectStats);
  }

  if (!context) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <button
        onClick={() => navigate("/home")}
        className="absolute top-8 left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          CONSULTA
        </h1>
        <p className="text-center flex gap-10 text-[var(--black)] font-mybold justify-center">
          TODOS OS REGISTOS
        </p>
        <div className="flex flex-col items-center w-full gap-2">
          <p className="text-left text-[var(--primary)] font-bold w-full">
            Filtrar:
          </p>
          <select
            name="stats"
            value={selectStats}
            className="select"
            onChange={(e) => setSelectStats(e.target.value)}
          >
            <option value="all">Todos os registos</option>
            <option value="reservado">Reservados</option>
            <option value="recolhido">Recolhidos</option>
            <option value="entregue">Entregues</option>
            <option value="cancelado">Cancelados</option>
          </select>
          <select
            name="brand"
            value={selectBrand}
            className="select"
            onChange={(e) => setSelectBrand(e.target.value)}
          >
            <option value="all">Todas as marcas</option>
            <option value="airpark">Airpark</option>
            <option value="redpark">Redpark</option>
            <option value="skypark">Skypark</option>
          </select>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="| PESQUISA"
            className="input"
          />
          <div className="flex items-center w-full gap-4">
            <p className="w-full">Reservas desde o dia:</p>
            <input
              value={beginDate}
              onChange={(e) => setBeginDate(e.target.value)}
              type="date"
              className="input"
            />
          </div>
          {type === "Admin" && (
            <div className="flex justify-between w-full">
              <p>Reservas com ocorrência: </p>
              <input
                type="checkbox"
                checked={showOcorrences}
                onChange={() => setShowOcorrences(!showOcorrences)}
                className="w-6 h-6 accent-[var(--primary)]"
              />
            </div>
          )}
        </div>
        {(type === "Admin" || type === "Admin") && (
          <div className="flex w-full gap-4">
            <button
              onClick={() => navigate("/import")}
              className="w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-white flex gap-2 items-center justify-center"
            >
              IMPORTAR
            </button>
            <button
              onClick={handleExport}
              className="w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-full py-2 text-black flex gap-2 items-center justify-center"
            >
              EXPORTAR
              <img src={DownloadIcon} alt="icon" className="w-6" />
            </button>
          </div>
        )}
        {cards && cards.length > 0 && (
          <p className="w-full font-bold text-center">Total: {cards.length}</p>
        )}
        <div className="flex flex-col w-full gap-2">
          {isLoading ? (
            <p className="text-center">Loading...</p>
          ) : cards && cards.length > 0 ? (
            cards.slice(0, 50).map((card: any, index: any) => {
              return (
                <Card
                  key={index}
                  client={card}
                  href={"consulta"}
                  type={"consulta"}
                />
              );
            })
          ) : (
            <p className="text-center">Nenhuma reserva encontrada</p>
          )}
        </div>
      </div>
    </>
  );
}
